/**
 *
 * You can write your CSS code here, DO NOT touch the default JavaScript file
 * because it will make it harder for you to update.
 *
 */

.h-100 {
  height: 100%;
}
.duration-200 {
  transition-duration: .2s;
}
.ease-out {
  transition-timing-function: cubic-bezier(0,0,.2,1);
}
.transform {
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(0) scaleY(0);
}
.origin-top-left {
  transform-origin: top left;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.scale-x-90 {
  transform: scaleX(.9);
}
.scale-y-90 {
  transform: scaleY(.9);
}
.scale-x-100 {
  transform: scaleX(1);
}
.scale-y-100 {
  transform: scaleY(0);
}
.StripeElement--invalid {
  border-color: #ff2c01!important;
}

.select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: right center;
}
select.form-control {
  @extend .select;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.dropdown-menu-arrow {
  top: -25px;
  left: 50%;
  width: 0;
  height: 0;
  position: relative;
}
//.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  left: -50px;
  z-index: 1001;
}
.dropdown-menu-arrow:after {
  bottom: -18px;
  border-bottom-color: #fff;
}
//.dropdown-menu-arrow:before {
//  bottom: -17px;
//  border-bottom-color: rgba(0,0,0,.15);
//}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { margin-left: 5px; }

.dataTables_wrapper td>img {
  background: #cdcdcd;
}
